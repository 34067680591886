import dynamic from 'next/dynamic';

// Dynamically import the page component with loading optimization
const DynamicPage = dynamic(() => import('./[...slug]'), {
  loading: () => null,
  ssr: true
});

// Re-export the component with dynamic import
export default DynamicPage;

// Re-export getServerSideProps
export { getServerSideProps } from './[...slug]';